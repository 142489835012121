@import 'animate.css';

#aboutMe {
  max-width: 1200px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px 0;
  animation: fadeIn 1s forwards;
}



#ownerImg {
  border-radius: 8px;
  max-height: 350px;
  max-width: 300px;
  padding: 24px 0;
  animation: fadeInLeft  1s forwards;
}

p {
  height: auto;
  max-width: 700px;
  padding: 16px 50px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: visible;
  position: relative;
  font-weight: 400;
  font-style: normal;
  color: #260c1a;
  font-size: 22px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
  animation: fadeInRight  1s forwards;
}


@media screen and (max-width: 768px) {
  #aboutMe {
    max-width: 500px;
    height: auto;
    padding-top: 90px;
  }

  #ownerImg {
    aspect-ratio: auto;
    max-width: 250px;
  }

  p {
    font-size: 20px;
  }
}

@media screen and (max-width: 425px) {
  #aboutMe {
    max-width: 350px;
  }

  #ownerImg {
    aspect-ratio: auto;
    max-width: 130px;
  }

  p {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px ) {
  #aboutMe {
    height: auto;
    padding-top: 90px;
    max-width: 400px;
  }
  
  #ownerImg {
    aspect-ratio: auto;
    max-width: 120px;
  }

  p {
    font-size: 16px;
  }
}