*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: Roboto; */
  font-weight: 200;

  background-color: #fff;
  /* background-color:seagreen; */
  color: #545469;
  overflow-x: hidden;
  line-height: 1.5;
}

#work {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

a {
  text-decoration: none;
  
}

/* .container > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  pointer-events: none;
  justify-content: start !important;
  font-family: "Kanit", sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

.number {
  font-size: 450px;
  color: #373c4c;
}

.number span {
  display: inline-block;
  position: relative;
  transform: translate3d(-35%, 0, 0);
}

.header {
  margin-left: 350px;
  font-size: 70px;
  color: white;
}

.stripe {
  height: 2px;
  width: auto;
}

.slopeBegin {
  background-color: #20232f;
  clip-path: polygon(20vw 0, 70% 0, calc(70% - 20vw) 100%, 0 100%);
}

.slopeEnd {
  clip-path: polygon(
    70% 0,
    100% 0,
    calc(100% - 20vw) 100%,
    calc(70% - 20vw) 100%
  );
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 140%;
  height: 100%;
  cursor: pointer;
}

.pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
} */
